@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@fontsource/nunito/400.css';
@import '@fontsource/nunito/700.css';


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@layer utilities {
    .clip-diagonal {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
    }
  }
  